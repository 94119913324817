import React, { useContext, useMemo, useState } from 'react'
import { Form } from 'react-bootstrap';
import { Controller, useForm } from "react-hook-form";
import ReservaContext from '../../../context/ReservaContext'
import TablaResumen from '../TablaResumen';
import { ApiPaths, TIPO_SERVICIOS } from '../../../utils';
import useTraeDatos from "../../hooks/useTraeDatos";
import ServiciosVehiculos from '../Servicios/ServiciosVehiculos';
import ServiciosGuianza from '../Servicios/ServiciosGuianza';
import UserContext from '../../../context/UserContext';
import { OPERADOR, SUPERADMIN, TAQUILLA, VISITANTE } from '../../../constantes/roles';
import { toast } from 'react-toastify';
import moment from 'moment';

const TransporteGuianza = () => {
    const { aumentarCambioVista, serviciosTotal, retrocederCambioVista, step, cambiarServiciosTotal } = useContext(ReservaContext)

    const { user } = useContext(UserContext);

    const validarZonaPadre = serviciosTotal.tipoIngreso.padre ? parseInt(serviciosTotal.tipoIngreso.padre) : parseInt(serviciosTotal.tipoIngreso.id);
    const [servicios] = useTraeDatos(ApiPaths.servicios + "?tipo_ingreso=" + validarZonaPadre);

    const serviciosTransporte = servicios.filter(({ tipo }) => tipo === TIPO_SERVICIOS.vehiculo || tipo === TIPO_SERVICIOS.alquiler || tipo === TIPO_SERVICIOS.vehiculoCodigo);
    const serviciosGuianza = servicios.filter(({ tipo }) => tipo === TIPO_SERVICIOS.guianza);

    const { register, handleSubmit, watch, control, setValue, formState: { errors } } = useForm({
        defaultValues: {
            servicioGuia: serviciosTotal.servicioGuia,
            servicioVehiculos: serviciosTotal.servicioVehiculos
        }
    });

    const totalGuianza = parseInt(serviciosGuianza[0]?.tarifa) * serviciosTotal.entradas;
    const [totalVehiculo, setTotalVehiculo] = useState(serviciosTotal.totalServicioVehiculo || 0)
    const [tipoServicioVehiculo, setTipoServicioVehiculo] = useState(serviciosTotal.tipoServicioVehiculo || '')

    function verificarGuia(servicios) {
        if (user?.roll == TAQUILLA || user?.roll == SUPERADMIN || user?.roll == OPERADOR) {
            for (let i = 0; i < servicios.length; i++) {
                if (!servicios[i].guia) {
                    toast.error('Todos los servicios de guianza deben tener guía asignado');
                    return false; // Si encuentras un servicio sin guía, retorna false y no dejes pasar
                }
            }
        }

        return true; // Si todos los servicios tienen guía, retorna true y deja pasar
    }

    const onSubmit = (data) => {
        if (validarZonaPadre == 2) {
            return aumentarCambioVista()
        }

        if (verificarGuia(watch('servicioGuia'))) {
            aumentarCambioVista()
        }
    }

    useMemo(() => {
        cambiarServiciosTotal({
            ...serviciosTotal,
            tipoServicioVehiculo,
            servicioVehiculos: watch('servicioVehiculos'),
            servicioGuia: watch('servicioGuia'),
            totalServicioGuia: totalGuianza,
            totalServicioVehiculo: totalVehiculo
        })

    }, [watch('servicioVehiculos'), watch('servicioGuia'), totalVehiculo, totalGuianza, tipoServicioVehiculo])

    const cambiarStepsVehiculo = () => {
        retrocederCambioVista()
        cambiarServiciosTotal({
            ...serviciosTotal,
            botonHabilitado: true
        })
    }


    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-8'>
                        {serviciosTransporte && serviciosTransporte.length > 0 &&
                            <Controller
                                control={control}
                                name="servicioVehiculos"
                                rules={{ required: validarZonaPadre == 3 ? true : true }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                }) => (
                                    <ServiciosVehiculos
                                        titulo={TIPO_SERVICIOS.vehiculo}
                                        servicios={serviciosTransporte}
                                        serviciosTotal={serviciosTotal}
                                        tipoServicioVehiculoValue={tipoServicioVehiculo}
                                        actualizarServiciosGeneralesTotal={e => {
                                            onChange(e.servicioVehiculo);
                                            setTotalVehiculo(e.totalServicioVehiculo)
                                            setTipoServicioVehiculo(e.tipoServicioVehiculo)
                                        }} errors={errors}
                                        value={value}
                                        cambiarServiciosTotal={cambiarServiciosTotal}
                                    />
                                )}
                            />
                        }

                        {user && parseInt(user.roll) != VISITANTE && serviciosGuianza && serviciosGuianza.length > 0 &&
                            <Controller
                                control={control}
                                name="servicioGuia"
                                rules={{ required: validarZonaPadre == 2 ? false : validarZonaPadre == 3 ? true : true }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                }) => (
                                    <ServiciosGuianza
                                        titulo="Servicios Uton"
                                        servicios={serviciosGuianza} tipoIngreso={serviciosTotal.tipoIngreso.id} actualizarServiciosGeneralesTotal={(e) => { onChange(e.servicioGuia); }} serviciosTotal={serviciosTotal} value={value} errors={errors}
                                        cambiarServiciosTotal={cambiarServiciosTotal}
                                    />

                                )}
                            />
                        }

                    </div>
                    <div className='col-md-4'>
                        <TablaResumen
                            serviciosTotal={serviciosTotal}
                            botonAtras={cambiarStepsVehiculo}
                            step={step}
                        />
                    </div>
                </div>
            </div>
        </Form>
    )
}

export default TransporteGuianza