import React, { useContext, useState, useEffect, useMemo, useRef } from 'react'
import { Controller, set, useForm } from "react-hook-form";
import { Form } from 'react-bootstrap';
import Calendar from 'react-calendar';
import ReservaContext from '../../../context/ReservaContext'
import SelectTipoIngreso from "../SelectTipoIngreso";
import ContadorVisitantesList from "../ContadorVisitantesList";
import TablaResumen from '../TablaResumen';
import axios from 'axios';
import { ApiPaths } from '../../../utils';
import moment from 'moment';
import UserContext from '../../../context/UserContext';
import { toast } from 'react-toastify';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SUPERADMIN } from '../../../constantes/roles';

const Calendario = () => {
    const { aumentarCambioVista, serviciosTotal, retrocederCambioVista, step, cambiarServiciosTotal, eliminarServicio } = useContext(ReservaContext)
    const { register, handleSubmit, watch, control, setValue, resetField, formState: { errors } } = useForm({
        defaultValues: serviciosTotal
    });
    const [cargandoCalendario, setCargandoCalendario] = useState(false)
    const [fechasHabilitadas, setFechasHabilitadas] = useState([])
    const [fechasDeshabilitadas, setFechasDeshabilitadas] = useState([])
    const [idTipoIngreso, setidTipoIngreso] = useState()
    const [fechas, setFechas] = useState([])
    const { tiposDeIngreso: tiposIngresos, user } = useContext(UserContext)
    const [fechaMinimaCalendario, setFechaMinimaCalendario] = useState()
    const [senderosOptions, setSenderosOptions] = useState([]);
    const senderoMultipleId = useRef()
    const senderoMultipleOptions = useRef()
    const [fechaMinimaCalendarioMultiple, setFechaMinimaCalendarioMultiple] = useState()
    const [senderosDias, setSenderosDias] = useState([])
    const [vistaCalendario, setVistaCalendario] = useState(1)
    const [fechaMaximaCalendario, setFechaMaximaCalendario] = useState()
    const [cargarSenderos, setCargarSenderos] = useState(false)
    const senderoMultiple = watch("tipoIngreso")?.senderoMultiple === 'true';

    const fecha1 = moment(watch('fechas')?.[0]);
    const fecha2 = moment(watch('fechas')?.[1] || watch('fechas')?.[0]);

    const datesArray = watch('fechas') || [];

    const capacidadCupos = useRef(0)

    const fechaDiferencia = fecha2.diff(fecha1, 'days') + 1

    useEffect(() => {
        if (serviciosTotal.tipoIngreso) {
            setidTipoIngreso(serviciosTotal.tipoIngreso.id)
            validarDiaSeleccionado(serviciosTotal.fechas && serviciosTotal.fechas[0])
            traerDatosCalendario(moment(fecha1).format("YYYY-MM-DD"), moment(fecha2).format("YYYY-MM-") + moment(fecha2).daysInMonth(), serviciosTotal.tipoIngreso.id)
            setSenderosDias(serviciosTotal.senderosDias)
            capacidadCupos.current = serviciosTotal.cuposDisponibles
        }
    }, [])

    const traerDatosCalendario = async (fechaInicial, fechaFinal, tipoIngreso) => {
        try {
            setCargandoCalendario(true)
            const { data } = await axios(ApiPaths.reservas, { params: { fechaInicial, fechaFinal, id: tipoIngreso } })
            const fechasHabilitadasArray = []
            const fechasDeshabilitadasArray = []
            const fechasArray = []


            const tipoIngresoBuscar = tiposIngresos.find((tipo) => tipo.id == tipoIngreso)

            const alertaArr = JSON.parse(tipoIngresoBuscar.alertas)
            const PocasExis = alertaArr[1]
            const EnExis = alertaArr[2]

            for (const [key, value] of Object.entries(data)) {
                if (value < parseInt(tipoIngresoBuscar.capacidadlimite)) {
                    if (value >= parseInt(PocasExis.rangoini) && value <= parseInt(PocasExis.rangofin)) {
                        fechasHabilitadasArray.push({ fecha: moment(key).format(), colorName: 'Amarillo', cantidad: value })
                    } else if (value >= parseInt(EnExis.rangoini)) {
                        fechasHabilitadasArray.push({ fecha: moment(key).format(), colorName: 'Amarillo', cantidad: value })
                    }
                } else {
                    fechasHabilitadasArray.push({ fecha: moment(key).format(), colorName: 'Rojo disabled', cantidad: value })
                }
                fechasArray.push({ fecha: key, colorName: 'Amarillo', cantidad: value })
            }
            setFechas(fechasArray)
            setFechasHabilitadas(fechasHabilitadasArray)
            setFechasDeshabilitadas(fechasDeshabilitadasArray)
            setCargandoCalendario(false)
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmit = (data) => {
        let hasError = false;

        if (senderoMultiple) {
            for (const item of senderosDias) {
                if (!item.fecha && fechaDiferencia >= item.dia) {
                    toast.error(`El sendero con dia ${item.dia} no tiene una fecha valida.`);
                    hasError = true;
                    break; // Termina el bucle al encontrar el primer error
                }
            }
        } else {
            aumentarCambioVista();
        }

        if (!hasError) {
            aumentarCambioVista();
        }

    }

    const deshabilitarFechas = (date) => {
        const dateobj =
            fechasDeshabilitadas &&
            fechasDeshabilitadas.find((x) => {
                return (
                    date.getDay() === new Date(x.fecha).getDay() &&
                    date.getMonth() === new Date(x.fecha).getMonth() &&
                    date.getDate() === new Date(x.fecha).getDate()
                );
            });
        return dateobj;
    }

    const habilitarFechas = (date, view) => {
        const dateobj =
            fechasHabilitadas &&
            fechasHabilitadas.find((x) => {
                return (
                    date.getDay() === new Date(x.fecha).getDay() &&
                    date.getMonth() === new Date(x.fecha).getMonth() &&
                    date.getDate() === new Date(x.fecha).getDate()
                );
            });
        return dateobj ? dateobj.colorName : "";
    };

    const cambiarFechaCalendario = (fecha) => {
        traerDatosCalendario(moment(fecha).format("YYYY-MM-DD"), moment(fecha).format("YYYY-MM-") + moment(fecha, "YYYY-MM").daysInMonth(), watch('tipoIngreso')?.id)
    }

    const cambiarFechaCalendarioMultiple = (fecha) => {
        traerDatosCalendario(moment(fecha).format("YYYY-MM-DD"), moment(fecha).format("YYYY-MM-") + moment(fecha, "YYYY-MM").daysInMonth(), senderoMultipleId.current)
    }

    const resetearCalendario = (e) => {
        setVistaCalendario(1);
        setFechasDeshabilitadas([])
        setFechasHabilitadas([])
        setValue('fechas', null);
        if (e?.id) {
            traerDatosCalendario(moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-") + moment().daysInMonth(), e.id)
        }
    }


    const validarDiaSeleccionado = (dia) => {
        if (serviciosTotal?.tipoIngreso?.padre == "3" && watch("fechas")?.length > 0) {

            const fechaInicial = moment(watch("fechas")[0]);
            const fechaFinal = moment(dia);

            const diasDiferencia = fechaFinal.diff(fechaInicial, 'days') + 1;

            if (diasDiferencia > 3) {
                cambiarServiciosTotal({
                    ...serviciosTotal,
                    botonHabilitado: false
                })
                toast.error("Las fechas seleccionadas no pueden superar 3 dias")
                return setValue('fechas', null);
            }
        }

        cambiarServiciosTotal({
            ...serviciosTotal,
            botonHabilitado: true
        })
    }

    if (!senderoMultiple) {
        if (!serviciosTotal.id) {
            const tipoIngresoBuscar = tiposIngresos?.find((tipo) => tipo.id == idTipoIngreso)

            watch('fechas') && watch('fechas').map((dia) => {
                dia = moment(dia).format("YYYY-MM-DD");

                const buscarDia = fechas.find(({ fecha }) => fecha === dia)

                const cantidad = parseInt(tipoIngresoBuscar?.capacidadlimite || 0) - (buscarDia ? buscarDia.cantidad : 0)
                if (capacidadCupos.current == 0 || cantidad <= capacidadCupos.current) {
                    capacidadCupos.current = cantidad;
                }
            })
        }

    }


    useMemo(() => {

        cambiarServiciosTotal({
            ...serviciosTotal,
            tipoIngreso: watch('tipoIngreso'),
            entradas: watch('tiposEntradas')?.contador,
            tipoEntradas: watch('tiposEntradas')?.data,
            seguroMedico: watch('tiposEntradas')?.contador,
            totalSeguros: 0,
            totalEntradas: watch('tiposEntradas')?.total,
            cuposDisponibles: capacidadCupos.current,
            fechas: watch('fechas'),
            tiposEntradas: watch('tiposEntradas'),
            senderosDias: senderosDias,
        })
        setFechaMinimaCalendario(new Date(moment(moment(), "MM-DD-YYYY").add(parseInt(watch('tipoIngreso')?.restriccionDias) + 1, 'days')))

    }, [watch('tipoIngreso'), watch('tiposEntradas'), watch('fechas'), capacidadCupos.current, senderosDias])



    function getDayByNumber(dayNumber) {
        const startDate = moment(datesArray[0]);
        const targetDate = startDate.clone().add(dayNumber - 1, 'days');
        return targetDate.format('YYYY-MM-DD');
    }

    const cambiarSenderoDia = (id, sendero, index) => {
        const fechaDia = getDayByNumber(sendero.dia);
        const fechaMinima = moment(fechaDia).toDate();
        const fechaMaxima = moment(fechaDia).toDate();
        setFechaMinimaCalendarioMultiple(fechaMinima)
        setFechaMaximaCalendario(fechaMaxima)
        setVistaCalendario(2)
        senderoMultipleId.current = id
        senderoMultipleOptions.current = sendero

        const senderoSeleccionado = senderosOptions.find((sen) => sen.id === id)
        setCargarSenderos(false)
        setSenderosDias(prevSenderosDias => {
            const nuevosSenderosDias = [...prevSenderosDias];
            nuevosSenderosDias[index] = {
                ...nuevosSenderosDias[index],
                senderoId: id,
                sendero: senderoSeleccionado,
            };
            return nuevosSenderosDias;
        });
        eliminarServicio('servicioGuia')
        cambiarFechaCalendarioMultiple(fechaDia)
    }
    const validarDiaSeleccionadoMultiple = (dia) => {
        const index = senderosDias.findIndex(({ dia }) => dia === senderoMultipleOptions.current.dia)
        setSenderosDias(senderosDias)
        setSenderosDias(prevSenderosDias => {
            const nuevosSenderosDias = [...prevSenderosDias];
            nuevosSenderosDias[index] = {
                ...nuevosSenderosDias[index],
                fecha: dia
            };
            return nuevosSenderosDias;
        });

        const tipoIngresoBuscar = tiposIngresos?.find((tipo) => tipo.id == senderoMultipleId.current)

        const diaFormato = moment(dia).format("YYYY-MM-DD");
        const buscarDia = fechas.find(({ fecha }) => fecha === diaFormato)
        const cantidad = parseInt(tipoIngresoBuscar?.capacidadlimite || 0) - (buscarDia ? buscarDia.cantidad : 0)

        if (capacidadCupos.current == 0 || cantidad <= capacidadCupos.current) {
            capacidadCupos.current = cantidad;
        }
    }

    const resetearCalendarioMultiple = () => {
        resetearCalendario(watch('tipoIngreso'))
        setSenderosDias([])
    }
    useEffect(() => {
        if (senderoMultiple && cargarSenderos) {
            const items = []
            for (let index = 0; index < fechaDiferencia; index++) {
                items.push({
                    dia: (index + 1),
                    fecha: null,
                    sendero: null,
                    nombre: 'Sendero Día ' + (index + 1),
                    senderoId: null
                })
            }
            setSenderosDias(items)
        }
    }, [watch('fechas')])

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-12">
                <div>
                    <div className='row'>
                        <div className='col-md-5'>

                            <Controller
                                control={control}
                                name="tipoIngreso"
                                rules={{ required: true }}
                                render={({
                                    field: { onChange, onBlur, value, name, ref },
                                }) => (
                                    <SelectTipoIngreso
                                        updateIngresoSelected={(e) => {
                                            onChange(e);
                                            setidTipoIngreso(e?.id);
                                            resetearCalendario(e);
                                        }} errors={errors} value={value}
                                        setSenderosOptions={setSenderosOptions}
                                        senderosOptions={senderosOptions}
                                    />
                                )}
                            />

                            <div className={!watch('fechas') ? "disable-all" : ""} >
                                <Controller
                                    control={control}
                                    name="tiposEntradas"
                                    rules={{ required: true }}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                    }) => (
                                        <ContadorVisitantesList
                                            tipoEntrada={watch('tipoIngreso')?.padre ? watch('tipoIngreso').padre : watch('tipoIngreso')?.id}
                                            errors={errors}
                                            totales={(e) => {
                                                onChange(e);
                                            }}
                                            limite={capacidadCupos.current}
                                            value={value}
                                        />
                                    )}
                                />

                            </div>

                        </div>
                        <div className='col-md-7'>
                            {senderoMultiple ?
                                <>
                                    <div className='d-flex gap-2'>
                                        {watch('fechas') && senderosDias?.map((senderoDia, index) => {
                                            const fecha = senderoDia.fecha ? moment(senderoDia.fecha).format('DD') : null;

                                            if (fechaDiferencia >= index + 1) {
                                                return (
                                                    <div className='d-flex flex-column justify-content-center' key={index}>
                                                        <p style={{ marginBottom: '1px' }} className='font-weight-bold'>{senderoDia.nombre} {fecha ? `(${fecha})` : null}</p>
                                                        <div className='ContSelectIngreso' style={{ marginBottom: 15 }}>
                                                            <select
                                                                className={"SelectIngreso"}
                                                                placeholder={senderoDia.nombre}
                                                                onChange={(e) => cambiarSenderoDia(e.target.value, senderoDia, index)}
                                                                value={senderoDia.senderoId || ''}
                                                            >
                                                                <option>{senderoDia.nombre}</option>
                                                                {senderosOptions.map((sendero, idx) => {
                                                                    if (sendero.id === '8' && parseInt(user.roll) !== SUPERADMIN) {
                                                                        return null;
                                                                    }
                                                                    if (sendero.senderoMultiple != 'true') return (
                                                                        <option key={idx} value={sendero.id}>{sendero.nombre}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                        {watch('fechas') && <div className='justify-content-center d-flex align-items-center'>
                                            <button
                                                onClick={resetearCalendarioMultiple}
                                                className="btn boton_reload_calendario btn-lg "
                                                type="button"
                                            >
                                                <FontAwesomeIcon icon={faRotateRight} />

                                            </button>
                                        </div>}

                                    </div>
                                    {vistaCalendario == 1 ?

                                        <div className={!watch('tipoIngreso') ? "disable-all" : ""}>
                                            <Controller
                                                control={control}
                                                name="fechas"
                                                rules={{ required: true }}
                                                render={({
                                                    field: { onChange, onBlur, value, name, ref },
                                                }) => (
                                                    <div className={cargandoCalendario ? 'filtering' : null}>
                                                        <div className='react-calendar-container'>
                                                            <Calendar
                                                                key={watch('tipoIngreso')?.id}
                                                                onChange={(fecha) => {
                                                                    (fecha.length > 0 ? onChange(fecha) : onChange([fecha]));
                                                                    setCargarSenderos(true)
                                                                }}
                                                                value={value}
                                                                className={`Calendario  react-calendar ${errors.fechas && 'is-invalid'}`}
                                                                minDate={fechaMinimaCalendario}
                                                                allowPartialRange={true}
                                                                selectRange={watch('tipoIngreso') && watch('tipoIngreso')?.multipleDias === 'true'}
                                                                tileClassName={({ date }) => habilitarFechas(date)}
                                                                tileDisabled={({ date }) => deshabilitarFechas(date)}
                                                                onActiveStartDateChange={({ activeStartDate }) => cambiarFechaCalendario(activeStartDate)}
                                                                onClickDay={validarDiaSeleccionado}

                                                            />

                                                        </div>

                                                    </div>
                                                )}
                                            />
                                        </div>
                                        :
                                        vistaCalendario == 2 ?
                                            <Controller
                                                control={control}
                                                name="fechasSenderos"
                                                rules={{ required: true }}
                                                render={({
                                                    field: { onChange, onBlur, value, name, ref },
                                                }) => (
                                                    <div className={cargandoCalendario ? 'filtering' : null}>
                                                        <div className='react-calendar-container'>
                                                            <Calendar
                                                                onChange={(fecha) => {
                                                                    (fecha.length > 0 ? onChange(fecha) : onChange([fecha]));
                                                                }}
                                                                className={`Calendario  react-calendar ${errors.fechasSenderos && 'is-invalid'}`}
                                                                minDate={fechaMinimaCalendarioMultiple}
                                                                maxDate={fechaMaximaCalendario}
                                                                tileClassName={({ date }) => habilitarFechas(date)}
                                                                tileDisabled={({ date }) => deshabilitarFechas(date)}
                                                                onClickDay={validarDiaSeleccionadoMultiple}
                                                                onActiveStartDateChange={({ activeStartDate }) => cambiarFechaCalendarioMultiple(activeStartDate)}
                                                            />

                                                        </div>

                                                    </div>
                                                )}
                                            />
                                            :
                                            null
                                    }

                                </>
                                :
                                <div className={!watch('tipoIngreso') ? "disable-all" : ""}>
                                    <Controller
                                        control={control}
                                        name="fechas"
                                        rules={{ required: true }}
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                        }) => (
                                            <div className={cargandoCalendario ? 'filtering' : null}>
                                                <div className='react-calendar-container'>
                                                    <Calendar
                                                        key={watch('tipoIngreso')?.id}
                                                        onChange={(fecha) => {
                                                            (fecha.length > 0 ? onChange(fecha) : onChange([fecha]));
                                                        }}
                                                        value={value}
                                                        className={`Calendario  react-calendar ${errors.fechas && 'is-invalid'}`}
                                                        minDate={fechaMinimaCalendario}
                                                        allowPartialRange={true}
                                                        selectRange={watch('tipoIngreso') && watch('tipoIngreso')?.multipleDias === 'true'}
                                                        tileClassName={({ date }) => habilitarFechas(date)}
                                                        tileDisabled={({ date }) => deshabilitarFechas(date)}
                                                        onActiveStartDateChange={({ activeStartDate }) => cambiarFechaCalendario(activeStartDate)}
                                                        onClickDay={validarDiaSeleccionado}
                                                    />

                                                </div>

                                            </div>
                                        )}
                                    />
                                </div>
                            }

                            <TablaResumen
                                serviciosTotal={serviciosTotal}
                                botonAtras={retrocederCambioVista}
                                step={step}
                            />
                        </div>
                    </div>

                    <br />
                </div>
            </div>
        </Form >
    )
}

export default Calendario